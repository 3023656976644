@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@100&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap');
/* body {
    width: 100vw;
    height: 100vh;
}

#__next {
    width: 100vw;
    height: 100vh;
} */

* {
    box-sizing: border-box;
    font-family: 'Inter', sans-serif;
    color: #565656;
    /* letter-spacing: 1px; */
}

button {
    border: none;
	cursor: pointer;
	outline: none;
	padding: 0;
	appearance: none;
}

body {
    transform: rotate(0.03deg);
    -ms-overflow-style: none;
}
body::-webkit-scrollbar  {
    display:none;
}